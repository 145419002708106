export function nFormatter(
  num?: number,
  opts: { digits?: number; full?: boolean } = {
    digits: 1,
  },
) {
  if (!num) return '0';
  if (opts.full) {
    return Intl.NumberFormat('en-US').format(num);
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(opts.digits).replace(rx, '$1') + item.symbol
    : '0';
}

/**
 * Formats a number to a percentage string.
 *
 * @param percent number between 0 and 1
 * @returns percent as a string with a percent sign
 */
export function formatPercent(percent: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 10,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 10,
  });
  const parts = formatter.formatToParts(percent);
  const integerString = parts.find(p => p.type === 'integer');
  const decimalString = parts.find(p => p.type === 'decimal');
  const fractionString = parts.find(p => p.type === 'fraction');
  const percentSign = parts.find(p => p.type === 'percentSign');

  if (integerString?.value === '100') {
    return '100%';
  }
  return [
    integerString?.value,
    decimalString?.value,
    fractionString?.value?.slice(0, 2),
    percentSign?.value,
  ].join('');
}

/**
 * Formats a distance no units included.
 *
 * @param distance number
 * @param decimals number of digits to display after the decimal point
 * @returns formatted distance
 */
export function formatDistance(distance: number, decimals = 2) {
  return distance.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function formatCurrency(amount: number) {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return USDollar.format(amount);
}

export function formatDuration(durationInSeconds: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  if (hours > 0) {
    return `${hours}h ${formatter.format(minutes)}m`;
  }
  return `${formatter.format(minutes)}m ${formatter.format(seconds)}s`;
}

export function formatPace(paceInSeconds: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const minutes = Math.floor(paceInSeconds / 60);
  const seconds = Math.floor(paceInSeconds % 60);
  return `${minutes}:${formatter.format(seconds)}`;
}

export function formatCount(count: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(count);
}

export function getPercent(numerator: number, denominator: number) {
  if (denominator === 0) {
    return 0;
  }
  const delta = denominator - numerator;
  if (delta < 0.000001) {
    return 1;
  }
  return numerator / denominator;
}
export const formatDateTimeLong = (
  date: Date,
  options?: {
    tz?: boolean;
    hideTime?: boolean;
    locale?: string | string[];
  },
) => {
  const formatter = new Intl.DateTimeFormat(options?.locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    minute: options?.hideTime ? undefined : 'numeric',
    hour: options?.hideTime ? undefined : 'numeric',
    timeZoneName: options?.tz ? 'short' : undefined,
  });

  return formatter.format(date);
};

export const formatDateTimeShort = (
  date: Date,
  options?: {
    tz?: boolean;
    hideTime?: boolean;
    locale?: string | string[];
  },
) => {
  const formatter = new Intl.DateTimeFormat(options?.locale, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    minute: options?.hideTime ? undefined : 'numeric',
    hour: options?.hideTime ? undefined : 'numeric',
    timeZoneName: options?.tz ? 'short' : undefined,
  });

  return formatter.format(date);
};
